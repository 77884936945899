import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../services/api';
import BASE_URL2 from '../images/api';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate and Link

const Home = () => {
  const { logout } = useAuth(); // Destructure logout from useAuth
  const navigate = useNavigate(); // Initialize useNavigate
  const [meals, setMeals] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);

  useEffect(() => {
    fetchMeals();
  }, []);

  const fetchMeals = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/meal-planning/meals`);
      setMeals(response.data);
      console.log(response);
    } catch (error) {
      toast.error('Failed to fetch meals');
    }
  };

  const handleViewClick = (meal) => {
    setSelectedMeal(meal);
    setShowProductModal(true);
  };

  const handleCloseModal = () => {
    setShowProductModal(false);
    setSelectedMeal(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <aside className="w-1/4 bg-gray-800 text-white p-4 overflow-y-auto">
        <ul>
          <li className="mb-4">
            <Link to="/">Home</Link>
          </li>
          <li className="mb-4">
            <Link to="/create-meal-plan">Create Meal Plan</Link>
          </li>
          <li className="mb-4">
            <Link to="/meal-plans">Meal Plans</Link>
          </li>
          <li className="mb-4">
            <Link to="/orders">Orders</Link>
          </li>
          <li className="mb-4">
            <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded-md">
              Logout
            </button>
          </li>
        </ul>
      </aside>
      <main className="w-3/4 p-4 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">Meals</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {meals.map((meal) => (
            <div key={meal.id} className="border p-4 rounded-lg shadow">
              <img src={`${BASE_URL2}${meal.image}`} alt={meal.name} className="w-full h-48 object-cover mb-4 rounded-lg" />
              <h2 className="text-xl font-semibold mb-2">{meal.name}</h2>
              <button
                onClick={() => handleViewClick(meal)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                View
              </button>
            </div>
          ))}
        </div>
      </main>
      {showProductModal && selectedMeal && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg max-w-md w-full md:max-w-3xl md:w-auto flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <img src={`${BASE_URL2}${selectedMeal.image}`} alt={selectedMeal.name} className="w-full h-full object-cover mb-4 rounded-lg" />
            </div>
            <div className="md:w-1/2 md:pl-4">
              <h2 className="text-xl font-semibold mb-4">{selectedMeal.name}</h2>
              <ul>
                {selectedMeal.ingredients.map((ingredient) => (
                  <li key={ingredient.id} className="mb-2">
                    {ingredient.item}: ${ingredient.cost}
                  </li>
                ))}
              </ul>
              <button onClick={handleCloseModal} className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Home;

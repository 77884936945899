import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import BASE_URL from '../services/api';
import BASE_URL2 from '../images/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom'; // Import Link and useNavigate

const CreateMealPlan = () => {
  const { state, logout } = useAuth();
  const { userId } = state;
  const [name, setName] = useState('');
  const [meals, setMeals] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeals = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/meal-planning/meals`);
        setMeals(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchMeals();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('name', name);
    formData.append('meals', JSON.stringify(selectedMeals));

    try {
      await axios.post(`${BASE_URL}/meal-planning/meal-plans`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Meal plan created successfully');
      setTimeout(() => {
        navigate('/meal-plans');
      }, 2000);
    } catch (error) {
      console.error('Error creating meal plan', error);
      toast.error('Failed to create meal plan');
    }
  };

  const handleMealSelect = (mealId) => {
    setSelectedMeals((prevSelected) =>
      prevSelected.includes(mealId)
        ? prevSelected.filter((id) => id !== mealId)
        : [...prevSelected, mealId]
    );
  };

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to login page after logout
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex h-screen overflow-hidden">
      <aside className="w-1/4 bg-gray-800 text-white p-4 overflow-y-auto">
        <ul>
          <li className="mb-4">
            <Link to="/">Home</Link>
          </li>
          <li className="mb-4">
            <Link to="/create-meal-plan">Create Meal Plan</Link>
          </li>
          <li className="mb-4">
            <Link to="/meal-plans">Meal Plans</Link>
          </li>
          <li className="mb-4">
            <Link to="/orders">Orders</Link>
          </li>
          <li className="mb-4">
            <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded-md">
              Logout
            </button>
          </li>
        </ul>
      </aside>
      <main className="flex-1 p-4 overflow-y-auto">
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4 text-center">Create Meal Plan</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Meal Plan Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-bold mb-2">Select Meals</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {meals.map((meal) => (
                  <div
                    key={meal.id}
                    className={`p-4 border rounded cursor-pointer ${selectedMeals.includes(meal.id) ? 'bg-blue-100' : ''}`}
                    onClick={() => handleMealSelect(meal.id)}
                  >
                    <h4 className="text-lg font-bold">{meal.name}</h4>
                    <img src={`${BASE_URL2}${meal.image}`} alt={meal.name} className="w-32 h-32" />
                  </div>
                ))}
              </div>
            </div>
            <button type="submit" className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600">
              Create Meal Plan
            </button>
          </form>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default CreateMealPlan;

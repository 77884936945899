import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import BASE_URL from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom'; // Import Link and useNavigate


const Orders = () => {
  const { state, logout } = useAuth();
  const { userId } = state;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/meal-planning/orders/user/${userId}`);
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchOrders();
  }, [userId]);

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to login page after logout
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex h-screen overflow-hidden">
      <aside className="w-1/4 bg-gray-800 text-white p-4 overflow-y-auto">
        <ul>
          <li className="mb-4">
            <Link to="/">Home</Link>
          </li>
          <li className="mb-4">
            <Link to="/create-meal-plan">Create Meal Plan</Link>
          </li>
          <li className="mb-4">
            <Link to="/meal-plans">Meal Plans</Link>
          </li>
          <li className="mb-4">
            <Link to="/orders">Orders</Link>
          </li>
          <li className="mb-4">
            <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded-md">
              Logout
            </button>
          </li>
        </ul>
      </aside>
      <main className="w-3/4 p-4 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">Orders</h1>
        {orders.map((order) => (
          <div key={order.id} className="border p-4 rounded-lg shadow mb-4">
            <h2 className="text-xl font-semibold mb-2">Order ID: {order.id}</h2>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-200">Ingredient</th>
                  <th className="py-2 px-4 bg-gray-200">Cost</th>
                </tr>
              </thead>
              <tbody>
                {order.meals.map((meal) =>
                  meal.ingredients.map((ingredient) => (
                    <tr key={ingredient.id}>
                      <td className="border px-4 py-2">{ingredient.item}</td>
                      <td className="border px-4 py-2">${ingredient.cost}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <p className="mt-4 font-bold">Total Cost: ${order.total_cost}</p>
          </div>
        ))}
      </main>
      <ToastContainer />
    </div>
  );
};

export default Orders;

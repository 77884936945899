import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom'; // Import Link and useNavigate
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../services/api';

const Mealplans = () => {
  const { state, logout } = useAuth(); // Destructure logout from useAuth
  const { userId } = state;
  const [mealPlans, setMealPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMealPlans = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/meal-planning/meal-plans/user/${userId}`);
        setMealPlans(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchMealPlans();
  }, [userId]);

  const handlePlaceOrder = async (mealIds) => {
    try {
      await axios.post(`${BASE_URL}/meal-planning/orders`, {
        userId,
        meals: mealIds,
      });
      toast.success('Order placed successfully', {
        onClose: () => navigate('/orders')
      });
    } catch (error) {
      console.error('Error placing order', error);
      toast.error('Failed to place order');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to login page after logout
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex h-screen overflow-hidden">
      <aside className="w-1/4 bg-gray-800 text-white p-4 overflow-y-auto">
      <ul>
          <li className="mb-4">
            <Link to="/">Home</Link>
          </li>
          <li className="mb-4">
            <Link to="/create-meal-plan">Create Meal Plan</Link>
          </li>
          <li className="mb-4">
            <Link to="/meal-plans">Meal Plans</Link>
          </li>
          <li className="mb-4">
            <Link to="/orders">Orders</Link>
          </li>
          <li className="mb-4">
            <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded-md">
              Logout
            </button>
          </li>
        </ul>
      </aside>
      <main className="w-3/4 p-4 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">Meal Plans</h1>
        {mealPlans.map((plan) => (
          <div key={plan.id} className="border p-4 rounded-lg shadow mb-4">
            <h2 className="text-xl font-semibold mb-2">{plan.name}</h2>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-200">Meal Name</th>
                  <th className="py-2 px-4 bg-gray-200">Ingredients</th>
                </tr>
              </thead>
              <tbody>
                {plan.meals.map((meal) => (
                  <tr key={meal.id}>
                    <td className="border px-4 py-2">{meal.name}</td>
                    <td className="border px-4 py-2">
                      <ul>
                        {meal.ingredients.map((ingredient) => (
                          <li key={ingredient.id}>
                            {ingredient.item}: ${ingredient.cost}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={() => handlePlaceOrder(plan.meals.map(meal => meal.id))}
              className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
            >
              Place Order
            </button>
          </div>
        ))}
      </main>
      <ToastContainer />
    </div>
  );
};

export default Mealplans;
